import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";

interface GlobalContextValue {
    savePendingSettings: () => void;
    registerSettingsSaveCallback: (callback: (() => void) | null) => void;
}

const defaultValue: GlobalContextValue = {
    savePendingSettings: () => {},
    registerSettingsSaveCallback: () => {},
};

const GlobalContext = createContext<GlobalContextValue>(defaultValue);

export const useGlobalContext = () => useContext(GlobalContext);

interface GlobalContextProviderProps {
    children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalContextProviderProps> = ({ children }) => {
    const [saveCallback, setSaveCallbackState] = useState<(() => void) | null>(null);
    const [params, setParamsState] = useState<Record<string, string>>({});

    const registerSettingsSaveCallback = useCallback((callback: (() => void) | null) => {
        setSaveCallbackState(() => callback);
    }, []);

    const savePendingSettings = useCallback(() => {
        if (saveCallback) {
            saveCallback();
        }
    }, [saveCallback]);

    return (
        <GlobalContext.Provider
            value={{
                savePendingSettings,
                registerSettingsSaveCallback,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
