import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import "./navbar-public.scss";
import wytopiaLogo from "../../resources/images/Wytopia_logo-3.png";
import profileIcon from "../../resources/images/icons/profile-user.png";
import {removeAuthCookies} from "../../helper/auth-helpers/cookie-helper";
import { NavbarPublicProps } from "../../interfaces/analytics-interfaces/analytics-interface";

import gbFlagIcon from "../../resources/images/flags/united_kingdom.png";
import frFlagIcon from "../../resources/images/flags/france.png";
import deFlagIcon from "../../resources/images/flags/germany.png";

function NavbarPublic(props: NavbarPublicProps) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [connected, setConnected] = useState(false);
  const [activeTab, setActiveTab] = useState("home");

  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  useEffect(() => {
    const token = Cookies.get("tokenSession");
    setConnected(!!token);

    if (!i18n.language || !["en", "fr", "de"].includes(i18n.language)) {
      i18n.changeLanguage("en");
    }
  }, [i18n]);

  const handleLogout = () => {
    removeAuthCookies();
    setConnected(false);
    window.location.href = "/";
  };

  const shouldHideButtons = () => {
    return props?.isVisitingFromUnityApp && location.pathname === "/login";
  };

  const renderLoginOrAnalyticsLink = () => {
    if (shouldHideButtons() || props?.isInvited) {
      return null;
    }
    return connected ? (
      <Link
        className="nav-item-icon"
        to="/analytics"
        onClick={() => setActiveTab("analytics")}
      >
        <img src={profileIcon} alt="Profile" />
      </Link>
    ) : (
      <Link
        className="nav-item"
        to="/login"
        onClick={() => setActiveTab("login")}
      >
        {t("navigationBar.login")}
      </Link>
    );
  };

  const renderUserMenu = () => {
    return showMenu && connected ? (
      <ul className="menu">
        <Link
          className="menu-item"
          to="/analytics"
          onClick={() => setActiveTab("analytics")}
        >
          <li>{t("navigationBar.analytics")}</li>
        </Link>
        <Link
          className="menu-item"
          to="/settings"
          onClick={() => setActiveTab("settings")}
        >
          <li>{t("navigationBar.settings")} </li>
        </Link>
        <Link className="menu-item" to="/login" onClick={handleLogout}>
          <li>{t("navigationBar.logout")} </li>
        </Link>
      </ul>
    ) : null;
  };

  const handleLogoClick = (e) => {
    if (props?.isVisitingFromUnityApp) {
      e.preventDefault();
    } else {
      setActiveTab("about");
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  const languageFlags = {
    en: gbFlagIcon,
    fr: frFlagIcon,
    de: deFlagIcon,
  };

  const renderLanguageButton = () => {
    const flagIcon = languageFlags[i18n.language] || languageFlags["en"];
    return (
      <button className="btn-lang-analytics" onClick={toggleLanguageMenu}>
        <img src={flagIcon} alt={i18n.language || "en"} />
        {languageMenuOpen && (
          <ul className="menu-user-lang">{renderLanguageOptions()}</ul>
        )}
      </button>
    );
  };

  const renderLanguageOptions = () => {
    const languages = ["fr", "en", "de"];
    return languages.map(
      (lang) =>
        i18n.language !== lang && (
          <li
            key={lang}
            className="menu-user-item-lang"
            onClick={() => {
              i18n.changeLanguage(lang);
              toggleLanguageMenu();
            }}
          >
            <img src={languageFlags[lang]} alt={lang} />
          </li>
        )
    );
  };

  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="white-decoration square" />
        <div className="white-decoration triangle" />

        <div className="nav-logo">
          <Link to="/" onClick={handleLogoClick}>
            <img src={wytopiaLogo} alt="logo wytopia" />
          </Link>
        </div>

        <div className="nav-menu">
          <div className="nav-right">
            {renderLanguageButton()}
            {renderLoginOrAnalyticsLink()}
            {renderUserMenu()}
          </div>
        </div>
        <div className="orange-decoration triangle" />
        <div className="orange-decoration square" />
      </div>
    </div>
  );
}

export default NavbarPublic;
