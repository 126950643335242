import React, {useEffect, useState} from "react";

import "./profile-selector-mobile.scss";

import AvatarAnimal1 from "../../../resources/images/avatars/Plan-1.png";
import arrowLeftIcon from "../../../resources/images/icons/gauche_violet.png";
import arrowRightIcon from "../../../resources/images/icons/droite_violet.png";
import {ProfileSelectorMobileProps} from "../../../interfaces/analytics-interfaces/analytics-interface";
import {findChildIndex} from "../../../helper/common-helpers/params-helper";
import Cookies from "js-cookie";
import {useGlobalContext} from "../../../pages/auth/global-context";

const ProfileSelectorMobile: React.FC<ProfileSelectorMobileProps> = (
    props: ProfileSelectorMobileProps
) => {
    const initialIndex = props?.referentData?.children
        ? findChildIndex(
            props.currentChildSelected?.childId ?? 0,
            props.referentData?.children
        )
        : 0;

    const [currentChildIndex, setCurrentChildIndex] = useState(initialIndex);

    const [fontSize, setFontSize] = React.useState("24px"); // Taille par défaut
    const { savePendingSettings } = useGlobalContext();

    const switchChild = (direction: "left" | "right") => {
        if (
            !props?.referentData?.children ||
            props?.referentData?.children.length === 0
        ) {
            return;
        }

        const children = props.referentData.children;

        let newIndex = currentChildIndex;

        if (direction === "left") {
            newIndex = newIndex === 0 ? children.length - 1 : newIndex - 1;
        } else {
            newIndex = (newIndex + 1) % children.length;
        }

        savePendingSettings();

        Cookies.remove("appChildId");
        setCurrentChildIndex(newIndex);
        props.setCurrentChildSelected(children[newIndex]);

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("childId");
        const newQueryString = urlParams.toString();
        const newUrl = newQueryString
            ? `${window.location.pathname}?${newQueryString}`
            : window.location.pathname;

        window.history.replaceState(null, "", newUrl);
    };


    useEffect(() => {
        const newIndex = props.referentData?.children
            ? findChildIndex(
                props.currentChildSelected?.childId ?? 0,
                props.referentData?.children
            )
            : 0;
        setCurrentChildIndex(newIndex);
    }, [props.currentChildSelected, props.referentData]);

    const currentAvatar = props.avatars.find(
        (avatar) => avatar.avatarID === Number(props.currentChildSelected?.avatarId)
    );

    return (
        <div className="profile-selector-mobile">
            <div className="top-section">
                <img
                    src={arrowLeftIcon}
                    onClick={() => switchChild("left")}
                    alt="Arrow Left"
                    className="arrow-icon"
                />
                <img
                    src={currentAvatar?.path || AvatarAnimal1}
                    alt="User Avatar"
                    className="avatar"
                />
                <img
                    src={arrowRightIcon}
                    onClick={() => switchChild("right")}
                    alt="Arrow Right"
                    className="arrow-icon"
                />
            </div>
            <div className="bot-section">
                <div className="profile-username" style={{fontSize: fontSize}}>
                    {props.childName?.toUpperCase()}
                </div>
            </div>
        </div>
    );
};

export default ProfileSelectorMobile;