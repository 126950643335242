import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";

// translation
import "./i18n";

// components
import App from "./App";

// styles
import "./index.css";
import {GlobalProvider} from "./pages/auth/global-context";
import {AuthProvider} from "./pages/auth/auth-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Router>
        <GlobalProvider>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </GlobalProvider>
    </Router>
    // </React.StrictMode>
);
