import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./download.scss";

import appIos from "../../resources/images/ios_logo.png";
import appAndroid from "../../resources/images/android_logo.png";

function Download() {
  const { t } = useTranslation();

  useEffect(() => {
    const expirationTime = 5 * 60 * 1000; // 5 minutes
    const alreadyRedirected = localStorage.getItem("alreadyRedirected");
    const expirationTimestamp = localStorage.getItem("redirectExpiration");

    const now = Date.now();

    if (expirationTimestamp && now > parseInt(expirationTimestamp, 10)) {
      localStorage.removeItem("alreadyRedirected");
      localStorage.removeItem("redirectExpiration");
    }

    if (!alreadyRedirected) {
      const userAgent = navigator.userAgent || navigator.vendor;

      if (/android/i.test(userAgent)) {
        window.location.href =
            "https://play.google.com/store/apps/details?id=com.WytopiaOrg.Wytopia";
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href =
            "https://apps.apple.com/fr/app/wytopia/id6449259890";
      }

      localStorage.setItem("alreadyRedirected", "true");
      localStorage.setItem(
          "redirectExpiration",
          (now + expirationTime).toString()
      );
    }
  }, []);

  return (
      <div className="download-page">
        <div className="download-container">
          <h1 className="download-title">
            {t("downloadPage.title")}
          </h1>
          <p className="download-subtitle">
            {t("downloadPage.subtitle")}
          </p>

          <div className="download-buttons">
            <a
                href="https://play.google.com/store/apps/details?id=com.WytopiaOrg.Wytopia"
                target="_blank"
                rel="noreferrer"
            >
              <img
                  className="app-android"
                  alt="logo download android app"
                  src={appAndroid}
              />
            </a>
            <a
                href="https://apps.apple.com/fr/app/wytopia/id6449259890"
                target="_blank"
                rel="noreferrer"
            >
              <img
                  className="app-ios"
                  alt="logo download ios app"
                  src={appIos}
              />
            </a>
          </div>
        </div>
      </div>
  );
}

export default Download;
