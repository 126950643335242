import {useState, useEffect, useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import "./navbar-analytics.scss";
import ProfileSelectorPc from "../profile/profile-selector/profile-selector-pc";
import ProfileSelectorMobile from "../profile/profile-selector/profile-selector-mobile";
import wytopiaLogo from "../../resources/images/Wytopia_logo-3.png";
import disconnectIconMobile from "../../resources/images/icons/sortie_violet.png";
import burgerIconMobile from "../../resources/images/icons/menu_violet.png";
import AnalyticsMenu from "../analytics/analytics-menu";
import { menuItems } from "../../constants/analytics-constants";
import { NavbarAnalyticsProps } from "../../interfaces/analytics-interfaces/analytics-interface";
import {fetchAvatarsApi} from "../../helper/apiHelper/rewards-api";
import {AvatarReward} from "../../interfaces/rewards-interfaces/rewards-interface";
import {AuthContext} from "../../pages/auth/auth-context";
import {removeAuthCookies} from "../../helper/auth-helpers/cookie-helper";
import {useGlobalContext} from "../../pages/auth/global-context";
// import { fetchAvatarsApi } from "../../api/rewards-api";
// import { AvatarReward } from "../../interfaces/rewards-interface/rewards-interface";

function NavbarAnalytics(props: NavbarAnalyticsProps) {
  const navigate = useNavigate();
  const { savePendingSettings } = useGlobalContext();

  const { isAuthenticated, setIsAuthenticated, referentData } = useContext(AuthContext);

  const [connected, setConnected] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [avatars, setAvatars] = useState<AvatarReward[]>([]);

  useEffect(() => {
    const token = Cookies.get("tokenSession");
    setConnected(!!token);

    const handleVuplexReady = () => {
      // console.log("Vuplex est maintenant prêt");
    };

    if (globalThis.vuplex) {
      // console.log("Vuplex est disponible");
    } else {
      window.addEventListener("vuplexready", handleVuplexReady);
    }

    return () => {
      window.removeEventListener("vuplexready", handleVuplexReady);
    };
  }, []);

  // Fetch avatars
  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await fetchAvatarsApi();
        if (response.status === 1 && response.object) {
          setAvatars(response.object);
        }
      } catch (error) {
        console.error("Error fetching avatars:", error);
      }
    };

    fetchAvatars();
  }, []);

  const handleCloseButton = () => {
    if (globalThis.vuplex) {
      globalThis.vuplex.postMessage({ type: "closeButtonClicked" });
      return;
    }
  };

  const handleLogout = () => {
    savePendingSettings();

    setIsAuthenticated(false);
    removeAuthCookies();
    
    const delayedAction = globalThis.vuplex
        ? handleCloseButton
        : () => {
          window.location.href = "/";
        };

    setTimeout(delayedAction, 1000);
  };


  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const handleHomeClick = () => {
    navigate("/analytics");
    setBurgerMenuOpen(false);
  };

  return (
      <div className="navbar-user">
        <div className="navbar-user-container">
          {props.isMobileScreenSize ? null : (
              <div className="nav-user-logo">
                <Link to="/" onClick={() => setActiveTab("about")}>
                  <img src={wytopiaLogo} alt="logo wytopia" />
                </Link>
              </div>
          )}

          {props.isMobileScreenSize && burgerMenuOpen ? (
              <div className="nav-user-menu-mobile">
                <AnalyticsMenu
                    items={menuItems}
                    toggleBurgerMenu={toggleBurgerMenu}
                    handleHomeClick={handleHomeClick}
                />
              </div>
          ) : null}

          <div className="nav-user-profile">
            {props.isMobileScreenSize ? (
                <>
                  <div className="nav-user-burger">
                    <button className="burger-btn" onClick={toggleBurgerMenu}>
                      <img src={burgerIconMobile} alt="Menu Burger Icon" />
                    </button>
                  </div>

                  <ProfileSelectorMobile
                      childName={props.currentChildSelected?.userName}
                      mobileLiteMode={props.mobileLiteMode}
                      referentData={props.referentData}
                      currentChildSelected={props.currentChildSelected}
                      setCurrentChildSelected={props.setCurrentChildSelected}
                      avatars={avatars} // Pass avatars here
                  />

                  <div className="nav-user-disconnect">
                    <button className="logout-btn" onClick={handleLogout}>
                      {props.isVisitingFromUnityApp ? null : (
                          <img src={disconnectIconMobile} alt="Logout Icon" />
                      )}
                    </button>
                  </div>
                </>
            ) : (
                <ProfileSelectorPc
                    childName={props.currentChildSelected?.userName}
                    handleLogout={handleLogout}
                    referentData={props.referentData}
                    currentChildSelected={props.currentChildSelected}
                    setCurrentChildSelected={props.setCurrentChildSelected}
                    avatars={avatars} // Pass avatars here
                />
            )}
          </div>
        </div>
      </div>
  );
}

export default NavbarAnalytics;
